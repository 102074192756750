// Use following syntax to prepend required libraries/scripts
// Use **/*.js if you don't need to follow a specific order
// You can override the order by requiring the JS file before the global require

//=require vendor/**/jquery.min.js
//=require vendor/**/*.js

(function ($) {
	"use strict";

	//checkbox
	$(".checkbox__text a").on("click", function () {
		$(".checkbox__modal").toggleClass("checkbox__modal-visible");
	});

	$(".checkbox__modal, .checkbox__text a").on("click", function (e) {
		e.stopPropagation();
	});

	$("body").on("click", function () {
		$(".checkbox__modal").removeClass("checkbox__modal-visible");
	});

	// product 
	$(".our-works button").on("click", function () {
		$(".products__items").toggleClass("products__items-visible");
	});
	$(".products__items, .our-works button").on("click", function (e) {
		e.stopPropagation();
	});

	$("body, .close__btn").on("click", function () {
		$(".products__items").removeClass("products__items-visible");
	});

})(jQuery);
